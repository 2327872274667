import {
  EnergyDeviceFromApi,
  EnergyPro,
  EnergySensor,
  SiteEnergyCalculationMode,
} from '@energybox/react-ui-library/dist/types';
import {
  SubscribedEnergyPro,
  EnergyProSensorReading,
} from '../reducers/subscribedEnergyPros';
import {
  ProcessedEnergySensor,
  ProcessedEnergySensorsById,
} from '../types/energyDevice';
import { PHASE_LABEL } from '../containers/Selects/SelectPhase';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';

export type ProcessedSubscribedEnergySensor = {
  indexString: string;
  powerActive: number;
  powerReactive: number;
  current: number;
  powerFactor: number;
  voltage: number;
  sensorId: number;
  isMainBreaker: boolean;
  breakerId?: number;
  breakerName?: string;
  energyDeviceId?: number;
  phase?: number;
  port?: number;
  isSiteTotal?: boolean;
  panelTitle?: string;
  equipmentTitle?: string;
  equipmentId?: number;
  isMainPanel: boolean;
  isTotal?: boolean;
};

export const processSubscribedEnergyProSensors = (
  subscribedEnergyPro: SubscribedEnergyPro | undefined,
  energySensorsById: ProcessedEnergySensorsById,
  isEnergyPro2?: boolean
): ProcessedSubscribedEnergySensor[] => {
  if (!subscribedEnergyPro) return [];

  const filteredSensors = isEnergyPro2
    ? subscribedEnergyPro.sensors.filter((s: EnergyProSensorReading) => {
        const energySensor = energySensorsById[s.id];
        return isDefined(energySensor?.id);
      })
    : subscribedEnergyPro.sensors;

  return filteredSensors.map((s: EnergyProSensorReading) => {
    const energySensor = energySensorsById[s.id];
    const { breaker, energyDeviceId, port, panelTitle, isMainPanel } =
      energySensor || {};
    const { id: breakerId, title: breakerName, _entity, siteTotal } =
      breaker || {};
    const isMainBreaker = _entity === 'MainBreaker';

    return {
      indexString: s.indexString,
      powerActive: s.powerActive,
      powerReactive: s.powerReactive,
      current: s.current,
      powerFactor: s.powerFactor,
      voltage: s.voltage,
      sensorId: s.id,
      equipmentTitle: breaker?.equipment?.title,
      equipmentId: breaker?.equipment?.id,
      breakerId,
      breakerName,
      isSiteTotal: siteTotal,
      isMainBreaker,
      energyDeviceId,
      phase: s.phase,
      port,
      panelTitle,
      isMainPanel,
    } as ProcessedSubscribedEnergySensor;
  });
};

export const getEnergyDeviceSensorsOfEnergyPro = (
  energyPro: EnergyPro | undefined,
  options: {
    mapEnergyDeviceSensorsReduxAction?: (
      sensors: EnergySensor[],
      energyDeviceId: number
    ) => void;
  } = {}
): ProcessedEnergySensor[] | undefined => {
  const { mapEnergyDeviceSensorsReduxAction } = options;
  const energyDeviceSensors: EnergySensor[] = [];
  if (!energyPro) return undefined;

  const recursivelyGetSensors = (energyDevice: EnergyDeviceFromApi | null) => {
    if (!energyDevice) return;

    if (energyDevice.sensors) {
      if (mapEnergyDeviceSensorsReduxAction) {
        mapEnergyDeviceSensorsReduxAction(
          energyDevice.sensors,
          energyDevice.id
        );
      }

      energyDeviceSensors.push(...energyDevice.sensors);
    }

    if (energyDevice.busDevice) {
      recursivelyGetSensors(energyDevice.busDevice);
    }
  };

  if (mapEnergyDeviceSensorsReduxAction) {
    mapEnergyDeviceSensorsReduxAction(energyPro.sensors, energyPro.id);
  }
  recursivelyGetSensors(energyPro.bus1Device);
  recursivelyGetSensors(energyPro.bus2Device);

  energyDeviceSensors.push(...energyPro.sensors);

  return energyDeviceSensors.map(s => {
    return {
      ...s,
      panelTitle: energyPro.distributionPanel?.title,
      isMainPanel: energyPro.distributionPanel?.mdp || false,
    };
  });
};

export const isEnergyPro1 = (energyPro: EnergyPro | undefined) => {
  return !isEnergyPro2(energyPro);
};

export const isEnergyPro2 = (energyPro: EnergyPro | undefined) => {
  return energyPro?.model === 'ENERGYPRO2';
};

const getBusDeviceSensors = (
  device: EnergyDeviceFromApi | null | undefined,
  mapping: {
    [breakerId: string]: EnergySensor[];
  }
) => {
  if (!device) return;
  getBusDeviceSensors(device.busDevice, mapping);
  device.sensors.forEach(sensor => {
    const energySensorsForThisBreaker = mapping[sensor.breakerId] || [];
    energySensorsForThisBreaker.push(sensor);
    mapping[sensor.breakerId] = energySensorsForThisBreaker;
  });
};

export const getBreakerToEnergySensorsMapping = (energyPro: EnergyPro) => {
  const bus1Device = energyPro.bus1Device;
  const bus2Device = energyPro.bus2Device;

  const energySensorsByBreakerId: {
    [breakerId: string]: EnergySensor[];
  } = {};

  getBusDeviceSensors(bus1Device, energySensorsByBreakerId);
  getBusDeviceSensors(bus2Device, energySensorsByBreakerId);

  return energySensorsByBreakerId;
};

export const convertCtToTitle = (ct: string) => {
  // EB_SPICT_02_100 to EB/SPICT-02-100
  // replace the first underscore with a slash and the rest with a dash
  return (
    ct?.replace(/_/g, '-')?.replace(/-/, '/') || ct || global.NOT_AVAILABLE
  );
};

export const isSiteTotalChecked = (
  isMainPanel: boolean,
  isMainBreaker: boolean,
  energyCalculationMode: SiteEnergyCalculationMode,
  siteTotal?: boolean
) => {
  switch (energyCalculationMode) {
    case SiteEnergyCalculationMode.FLAGGED_BREAKERS:
      return !!siteTotal;
    case SiteEnergyCalculationMode.MDP_MAINS:
      return isMainPanel && isMainBreaker;
    case SiteEnergyCalculationMode.MDP_BREAKERS:
      return isMainPanel && !isMainBreaker;
    case SiteEnergyCalculationMode.DP_MAINS:
      return !isMainPanel && isMainBreaker;
    case SiteEnergyCalculationMode.DP_BREAKERS:
      return !isMainPanel && !isMainBreaker;
  }
};
